class Helper {
	/** Determines the company name based on the hostname */
	get companyName() {
		return 'Hungry.dk'
	}

	get companyNameShort() {
		return 'Hungry'
	}

	get url() {
		return 'https://www.hungry.dk'
	}

	get ogImageSrc() {
		return 'https://assets.aws.hungrycloud.net/dk/media/manual/1200x630_hungry_tagline.png'
	}

	get ogTitle() {
		return 'Hungry.dk • Bestil Take Away Mad • Pizza, Burger, Sushi og mere'
	}
}

export default new Helper()
