import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import da from './da_DK.json'
import en from './en_IE.json'

// To add new translations, they must be added to the source.json file and uploaded to transifex
// To pull translations, run yarn translate-pull
// To update existing translations, run yarn translate-push

i18n
	// .use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				translation: en,
			},
			da: {
				translation: da,
			},
		},
		fallbackLng: 'da', // This should be infered from hostname?
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: true,
		},
	})

export default i18n
